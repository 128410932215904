<template>
    <div>
        <v-container>
            <notification-popup  
                :dialog="modalUsageCPM"
                :contentMsg="$t('Enjoyyourtrip')"
                :headerMsg="$t('ValidationSuccess')"
                :nameBtnNo="$t('labelNo')"
                :nameBtnYes="$t('BacktoRecentTrip')"
                disableBtnNo="true"
                colorIcon = "success"
                typeModal="transaction"
                Icon="mdi-check-circle"
                imgSource="notification/success2.svg"
                :onHandlerNo="cancelSuccess"
                :onHandlerYes="buttonOke"
            />

            <notification-popup
                :dialog="notifGetFailed"
                :contentMsg="contentMsg"
                :headerMsg="$t('FailedQrGenerateHeader')"
                :nameBtnNo="$t('Close')"
                :nameBtnYes="$t('GenerateQR')"
                colorIcon="danger"
                typeModal="transaction" 
                Icon="mdi-close-circle"
                imgSource="notification/failed.svg"
                :onHandlerYes="buttonGenerateTicket"
                :onHandlerNo="buttonClose"
            />
            
            <v-row class="mx-0 box mt-4" justify="center">
                <v-col cols="12">
                    <qrcode-vue :value="qrCode" :size="300" class="mt-10 mb-10 text-center"/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import store from "@/store";
import QrcodeVue from "qrcode.vue";
import NotificationPopup from "@/components/NotificationPopup.vue";
export default {
    name: "ShowQR",
    components: {
        QrcodeVue,
        NotificationPopup
    },
    data() {
        return {
            qrCode: "",
            notifGetFailed: false,
            contentMsg: ""
        }
    },
    computed: {
        customerID() {
            return store.state.auth.userData.customerID;
        },
        modalUsageCPM() {
            return this.$store.state.notification.modalUsage
        }
    },
    watch: {
        modalUsageCPM: function () {
            console.log(this.modalUsageCPM)
        }
    },
    methods: {
        loadInitial() {
            this.$store.commit("notification/SET_MODAL_USAGE", false);
            
            this.generateQR()
        },
        generateQR() {
            const payload = {
                customer_id: this.customerID
            }
            this.$store
                .dispatch("payment/paygGenerateQR", payload)
                .then((response) => {
                    if (response.status) {
                        this.qrCode = response.data.data
                    } else {
                        let message = response.message
                        if (message === '') {
                            message = 'PleaseTryAgain'
                        }
                        this.contentMsg = this.$t(message)
                        this.notifGetFailed = true
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        buttonClose() {
            this.notifGetFailed = false
        },
        buttonGenerateTicket() {
            this.loadInitial()
            this.notifGetFailed = false
        },
        cancelSuccess() {
            this.$store.commit("notification/SET_MODAL_USAGE", false);
        },
        buttonOke() {
            this.$store.commit("notification/SET_MODAL_USAGE", false);
            this.$router.push({ name: "MyTrip" });
        },
    },
    mounted() {
        this.loadInitial()
    },
}
</script>

<style scoped>
.box {
    background: #FFFFFF;
    border: 1px solid #4BB14E;
    border-radius: 10px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 10%), 0px 4px 8px rgb(0 0 0 / 5%);
}

.link {
    color: #4BB14E;
    text-decoration: underline;
}
</style>